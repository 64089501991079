import React from 'react';


export default function SkillsCard(props) {
    return (
        <div className='skills-card'>
                   <div className='skills-icon-size'>{props.Skillsicon}</div>
        </div> 
    )
    
}